<template>
  <div>
    <div class="menu">
      <span>마이페이지</span>
      <img src="@/assets/image/icn-arrow_right.svg" alt="arrow" />
      <span class="active">나의 설문</span>
    </div>
    <article class="inner">
      <el-collapse accordion v-if="surveyList.length > 0">
        <el-collapse-item v-for="(data, i) in surveyList" :key="i">
          <template slot="title">
            <span>{{ moment(data.createdAt).format("YYYY.MM.DD") }}</span>
            <h3>{{ data.post ? data.post.title : "삭제된 설문입니다." }}</h3>
            <span
              v-if="data.win == true"
              class="state"
              :class="{ ready: data.win == true, wait: data.win == false }"
              >당첨
            </span>
          </template>
          <div class="flexB">
            <div>
              <h4>
                설문 내역
                <router-link
                  to
                  @click.native="handleMove(data.post._id)"
                  class="flr"
                  >설문 상세페이지 이동
                  <img src="@/assets/image/arrow_right.svg" alt="" />
                </router-link>
              </h4>

              <p>
                <span>설문 시작일</span>
                {{
                  data.post
                    ? moment(data.post.startAt).format("YYYY.MM.DD")
                    : ""
                }}
              </p>

              <p v-if="data.post && data.post.limitType == 'PERIOD'">
                <span>설문 종료일</span>
                {{ moment(data.post.endAt).format("YYYY.MM.DD") }}
              </p>
              <p v-if="data.post && data.post.limitType == 'COUNT'">
                <span>참여인원</span>
                {{ data.post.surveyers.length }} / {{ data.post.userCount }}
              </p>
              <p>
                <span>설문 상태</span>
                <span
                  v-if="data.post"
                  :class="{ end: data.post.status == 'FINISH' }"
                >
                  {{
                    data.post.status == "FINISH" ? "설문 마감" : "설문 진행중"
                  }}</span
                >
              </p>
            </div>
            <div>
              <h4>참여 정보</h4>
              <p>
                <span>참여일</span>
                {{ moment(data.createdAt).format("YYYY년 MM월 DD일 HH:mm") }}
              </p>
              <p>
                <span>참여 금액</span>
                {{ numberFormat(data.amount) }}원
              </p>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <p v-else class="nodata">설문 내역이 없습니다.</p>
      <div class="pagination" v-if="surveyList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </article>
  </div>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";
import { format } from "@/mixins/format";
import { fetchUserSurveyList } from "@/api/survey";

export default {
  mixins: [format],
  data() {
    return {
      moment: moment,
      surveyList: [],
      total: 0,
      currentPage: 1,
    };
  },
  mounted() {
    this.getServeyDetail();
  },
  computed: {
    ...mapState(["userId"]),
  },
  methods: {
    handleMove(id) {
      this.$router.push({ name: "surveyDetail", params: { id: id } });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getServeyDetail();
    },
    getServeyDetail() {
      let params = {
        userId: this.userId,
        page: this.currentPage,
      };
      fetchUserSurveyList(params).then((res) => {
        if (res.data.status == 200) {
          this.surveyList = res.data.surveylist;
          this.total = res.data.total;
          this.currentPage = res.data.page;
        } else {
          return alert(res.data.message);
        }
      });
    },
  },
};
</script>
