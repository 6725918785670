<template>
  <div class="container mypage">
    <div class="top"></div>
    <div class="base flexB">
      <section class="contents myMenu">
        <article class="inner">
          <div class="profile">
            <!-- <img src="@/assets/image/noImage.png" alt="이미지" /> -->
            <!-- <h3>{{ username }}</h3>
            <p>{{ email }}</p>-->
            <h3>{{ username }}</h3>
            <p>{{ email }}</p>
            <p class="mypoint">{{ point }}P</p>
            <img v-if="level == 'SILVER'" src="@/assets/images/silver.svg" alt />
            <img v-if="level == 'GOLD'" src="@/assets/images/gold.svg" alt />
            <img v-if="level == 'DIAMOND'" src="@/assets/images/dia.svg" alt />
          </div>
          <ul>
            <li :class="{ active: path == 'mySurvey' }">
              <router-link to @click.native="handleTab('mySurvey')">나의 설문</router-link>
            </li>
            <!-- <li>
              <router-link to="/mypage/myQna">나의 문의내역</router-link>
            </li>-->
            <li :class="{ active: path == 'edit' }">
              <router-link to @click.native="handleTab('edit')">회원정보</router-link>
            </li>
            <li :class="{ active: path == 'pw' }">
              <router-link to @click.native="handleTab('pw')">비밀번호변경</router-link>
            </li>
            <li>
              <router-link to @click.native="withdraw">회원탈퇴</router-link>
            </li>
            <li>
              <router-link to @click.native="logout">로그아웃</router-link>
            </li>
          </ul>
        </article>
      </section>
      <section class="contents myWrap subpage">
        <article class="inner">
          <MySurvey v-if="path == 'mySurvey'" />
          <UserInfo v-else-if="path == 'edit'" />
          <UserPwd v-else-if="path == 'pw'" />
        </article>
      </section>
    </div>
  </div>
</template>
<script>
import MySurvey from "@/components/contents/MySurvey";
import UserInfo from "@/views/mypage/UserInfo";
import UserPwd from "@/views/mypage/UserPwd";
import { createWithdraw } from "@/api/index";

import { mapState } from "vuex";

export default {
  components: { MySurvey, UserInfo, UserPwd },
  data() {
    return {
      callActive: false,
      path: "mySurvey",
    };
  },
  computed: {
    ...mapState(["username", "userType", "email", "level", "point"]),
  },
  methods: {
    handleTab(name) {
      this.path = name;
    },
    withdraw() {
      const result = confirm("회원탈퇴 하시겠습니까? 회원탈퇴 후에는 모든 개인정보가 삭제됩니다.");
      if (result == false) {
        return;
      }
      createWithdraw().then((res) => {
        if (res.data.status == 200) {
          this.logout;
        } else {
          alert(res.data.message);
        }
      });
    },
    logout() {
      let result = confirm("로그아웃 하시겠습니까?");
      if (result == false) {
        return;
      }
      this.$store.dispatch("LOGOUT");
    },
  },
};
</script>
